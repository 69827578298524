.game-container {
  height: 100vh;
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  /* background-image: url('/src/assets/bg-static.png');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5px; */
  overflow: hidden;
}

.bg-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 999;
  overflow: hidden;
}
