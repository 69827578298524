@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  /* background-image: url('/src/assets/bg-static.png');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed; */
}

.button {
  width: 300px;
  height: 100px;
}
